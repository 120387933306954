
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataDownloader, get } from "@/api";
import ChallengeCard from "@/components/challenges/ChallengeCard.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import Newsletter from "@/components/common/Newsletter.vue";
import Content from "@/components/common/Content.vue";
import vSelect from "vue-select";
import { Challenge, ChallengeShortInfo } from "@/interfaces/challenge";
import i18n from "@/i18n";
import { challenge } from "@/interfaces";
const pageKey = "challenges";

@Component({
  components: {
    Newsletter,
    ChallengeCard,
    Content,
    VueperSlides,
    VueperSlide,
    vSelect,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class ChallengesOverview extends Vue {
  challenges = new DataDownloader<ChallengeShortInfo[]>(() => {
    if (this.$store.getters.isLoggedIn) return get.challengeShortInfo();
    else return get.challengePreview();
  });

  challengeLanguage = "";
  challengeLanguages: string[] = [];
  challengeCurrency = "";
  challengeCurrencies: string[] = [];

  challangeCardsHeight = 10;

  fakeLoad = false;
  loaderHeight = 0;

  @Watch("challenges.data")
  onChallengesDownload(newVal: Challenge[]) {
    if (newVal) {
      this.challengeLanguages = Array.from(
        new Set(newVal.map((challange) => challange.language))
      );
      this.challengeLanguage = this.$t(
        "languages-translated." + this.challengeLanguages[0]
      ) as string;
      this.challengeCurrencies = Array.from(
        new Set(newVal[0].prices.map((price) => price.currency))
      );
      this.challengeCurrency = "USD";
    }
  }

  @Watch("challengeLanguage")
  onChallangeLanguageChange() {
    if (this.$refs.challengesPicker) {
      this.loaderHeight = (
        this.$refs.challengesPicker as HTMLElement
      ).offsetHeight;
      this.fakeLoad = true;
      setTimeout(() => {
        this.fakeLoad = false;
        this.setChallangeCardsHeight(0);
      }, 300);
    } else this.setChallangeCardsHeight(0);
  }

  @Watch("challengeCurrency")
  onChallengeCurrencyChange() {
    if (this.$refs.challengesPicker) {
      this.loaderHeight = (
        this.$refs.challengesPicker as HTMLElement
      ).offsetHeight;
      this.fakeLoad = true;
      setTimeout(() => {
        this.fakeLoad = false;
        this.setChallangeCardsHeight(0);
      }, 500);
    }
  }

  get challengesSortedByLanguage() {
    return this.challenges.data?.filter(
      (challenge) =>
        this.$t("languages-translated." + challenge.language) ===
        this.challengeLanguage
    );
  }

  challengeCurrentPrice(challenge: Challenge) {
    return challenge.prices.find(
      (price) => price.currency === this.challengeCurrency
    )?.price;
  }

  setChallangeCardsHeight(delay = 100) {
    setTimeout(() => {
      this.challangeCardsHeight =
        Math.max(
          ...(this.$refs.challangeCards as Vue[]).map(
            (ref) => (ref.$el.children[0] as HTMLElement).offsetHeight
          )
        ) + 36;
      (this.$refs.challangeCards as Vue[]).forEach(
        (ref) => ((ref.$el.children[0] as HTMLElement).style.bottom = "1rem")
      );
    }, delay);
  }

  buyChallenge(challenge: Challenge) {
    this.$store.commit("setPayment", {
      id: challenge.id,
      title: challenge.title,
      prices: challenge.prices,
      currency: this.challengeCurrency,
      language: challenge.language,
    });
    this.$router.push("/payment-challenge");
  }

  trimDescription(desc: string) {
    if (desc.length > 150)
      for (let i = 151; i < desc.length; i++) {
        if (desc[i] === " ") return desc.slice(0, i) + "...";
      }
    else return desc;
  }
}
